import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { InView } from "react-intersection-observer"
import SubscribeFormContainer from "@containers/SubscribeForm"
import Footer from "../../UI/Footer"
import { BigWhiteCircle } from "./BlogBottomHeroCircles"
import { FooterDataType } from "@typings/index"
import { BlogContentDataType } from "@typings/requests/home/blog"
import "./blog-bottom-hero.scss"

interface BlogBottomHeroPropsTypes extends BlogContentDataType {
  lang: string
  footerData: FooterDataType["allStrapiPageFooter"]
}

const BlogBottomHero: React.FC<BlogBottomHeroPropsTypes> = ({
  footerData,
  subscribeButton,
  subscribeFailLabel,
  subscribeInputError,
  subscribeInputLabel,
  subscribeSuccessLabel,
  lang,
  subscribeImage,
  subscribeTitle,
}) => {
  const [isInView, setIsInView] = useState(false)

  return (
    <InView
      as="section"
      triggerOnce
      className="blog-bottom-hero"
      threshold={[0.1]}
      onChange={setIsInView}
    >
      <div className="container">
        <div className="blog-bottom-hero__picture">
          <BigWhiteCircle isView={isInView} />
          <GatsbyImage
            image={subscribeImage.localFile.childImageSharp.gatsbyImageData}
            alt=""
          />
        </div>
        <div className="blog-bottom-hero__info">
          <h2 className="blog-bottom-hero__title text text--h1">
            {subscribeTitle}
          </h2>
          <SubscribeFormContainer
            className="blog-bottom-hero__form"
            subscribeButton={subscribeButton}
            subscribeFailLabel={subscribeFailLabel}
            subscribeInputError={subscribeInputError}
            subscribeInputLabel={subscribeInputLabel}
            subscribeSuccessLabel={subscribeSuccessLabel}
            lang={lang}
          />
          <Footer className="blog-page-footer" {...footerData.nodes[0]} />
        </div>
      </div>
    </InView>
  )
}

export default BlogBottomHero
