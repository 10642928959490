import React from "react"
import cn from "classnames"
import { Parallax } from "react-scroll-parallax"
import Circle from "@components/Circle"
import { AnimatedCirclePropsTypes } from "@typings/props"

export const BigWhiteCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isView,
}) => (
  <div
    className={cn(
      "blog-bottom-hero-circle blog-bottom-hero-circle--big-white",
      {
        "h-in-view": isView,
      }
    )}
  >
    <Parallax translateY={[10, -10]}>
      <Circle color="#FFFFFF" />
    </Parallax>
  </div>
)
